import { createStore } from 'vuex';
import axiosConfig from '../lib/axiosConfig'; // Import the Axios instance
import userModule from './modules/userModule';
import articleModule from './modules/articleModule';
import hashModule from './modules/hashModule';
import appModule from './modules/appModule';
import collectionModule from './modules/collectionModule';
import channelModule from './modules/channelModule';
import materialModule from './modules/materialModule';
import segmentModule from './modules/segmentModule';
import tgConnectorModule from './modules/tgConnectorModule';
import narezkaModule from './modules/narezkaModule';

const store = createStore({
  modules: {
    hash: hashModule,
    user: userModule,
    article: articleModule,
    segment: segmentModule,
    app: appModule,
    collection: collectionModule,
    channel: channelModule,
    material: materialModule,
    tgconnector: tgConnectorModule,
    narezka: narezkaModule
  },
  state: {
    // Global state if needed
  },
  mutations: {
    // Global mutations if needed
  },
  actions: {
    // Global actions if needed
  },
  getters: {
    // Global getters if needed
  },
});

store.$axiosInstance = axiosConfig; // Set the axiosInstance in the store

export default store;
